import { Stack, useTheme } from "@mui/material";
import Sidebar from "../components/Sidebar";
import AppBar from "../components/AppBar";
import { Navigate, Route, Routes, useNavigate } from "react-router";
import SectionDashboard from "../sections/dashboard";
import SectionSettings from "../sections/settings";
import SectionCustomers from "../sections/customers";
import SectionBots from "../sections/bots";
import SectionAdmins from "../sections/admins";
import SectionCoupons from "../sections/coupons";
import SectionConfigs from "../sections/configs";

export default function PagePortal() {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        background: theme.palette.background.default,
      }}
      direction={"row"}
    >
      <Sidebar />
      <Stack
        flex={1}
        sx={{
          m: "32px",
          background: "rgba(19, 19, 19, 0.71)",
          borderRadius: "25px",
        }}
      >
        {/* App bar goes here  */}
        <AppBar />

        {/* Content goes here  */}
        <Stack flex={1} sx={{ overflow: "hidden" }}>
          <Routes>
            <Route path="settings" element={<SectionSettings />} />
            <Route path="dashboard" element={<SectionDashboard />} />
            <Route path="customers" element={<SectionCustomers />} />
            <Route path="admins" element={<SectionAdmins />} />
            <Route path="coupons" element={<SectionCoupons />} />
            <Route path="configs" element={<SectionConfigs />} />

            <Route path="bots" element={<SectionBots />} />

            <Route path="*" element={<Navigate to="dashboard" />} />
          </Routes>
        </Stack>
      </Stack>
    </Stack>
  );
}
