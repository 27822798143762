import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import LoadingOverlay from "./LoadingOverlay";
import { FbApp } from "../core/firebase";

export default function SplashScreen(props: any) {
	const [initialized, setInitialized] = useState(false);
	const auth = getAuth(FbApp);

	useEffect(() => {
		// Ping the server to see if its online.
		auth.onAuthStateChanged((user) => {
			// Auth state changed.
			setTimeout(() => {
				setInitialized(true);
			}, 1000);
		});
	}, []);

	if (initialized) {
		return <>{props.children}</>;
	} else {
		return (
			<LoadingOverlay
				title="Loading App..."
				hint="Waiting on firebase..."
			/>
		);
	}
}
