import React, { useEffect, useState } from "react";
import { DialogProps } from "./DialogProps";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import TextInput from "../components/TextInput";
import { GetConfig, SetConfig } from "../core/rest";
import { enqueueSnackbar } from "notistack";

export default function ServiceSettingsDialog(props: DialogProps<any>) {
  const [config, setConfig] = useState<{
    image: string;
    version: string;
    cpu: string;
    memory: string;
    maxCpu: string;
    maxMemory: string;
  }>();
  const [busy, setBusy] = useState(false);

  async function loadSettings() {
    setBusy(true);
    try {
      const cfg = await GetConfig("default_image");
      if (cfg) {
        setConfig(cfg);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading configuration. " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function saveConfig() {
    setBusy(true);
    try {
      await SetConfig("default_image", config);
      props.closeHandler();
    } catch (err: any) {
      enqueueSnackbar("Error saving configuration. " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    loadSettings();
  }, []);

  return (
    <Dialog open fullWidth onClose={props.closeHandler}>
      <DialogTitle>
        <Typography>Service Settings</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing="18px">
          <TextInput
            disabled={busy}
            key={config as any}
            value={config?.image}
            onUpdate={(t) =>
              setConfig({
                ...((config as any) || {}),
                image: t,
              })
            }
            label="Default Image"
            helperText="The default or current image that is used for new customers. "
          />
          <TextInput
            key={config as any}
            disabled={busy}
            value={config?.version}
            onUpdate={(t) =>
              setConfig({
                ...((config as any) || {}),
                version: t,
              })
            }
            label="Version Tag"
            helperText="The version tag of default or current image. "
          />
          <Typography>Resource Allocation</Typography>
          <Stack direction={"row"} justifyContent={"center"} spacing={"8px"}>
            <Typography sx={{ width: "250px" }}>Requests</Typography>
            <TextInput
              key={config as any}
              fullWidth
              disabled={busy}
              value={config?.cpu}
              onUpdate={(t) =>
                setConfig({
                  ...((config as any) || {}),
                  cpu: t,
                })
              }
              label="CPU"
              helperText="Requested amount of CPU in terms of Millicores. I.e. 500m for 0.5vCPU. Minimum is 250m."
            />
            <TextInput
              fullWidth
              key={config as any}
              disabled={busy}
              value={config?.memory}
              onUpdate={(t) =>
                setConfig({
                  ...((config as any) || {}),
                  memory: t,
                })
              }
              label="Memory"
              helperText="The amount of memory in bytes, or mebibytes (Mi). Minimum is 250Mi. "
            />
          </Stack>

          <Stack direction={"row"} justifyContent={"center"} spacing={"8px"}>
            <Typography sx={{ width: "250px" }}>Limits</Typography>
            <TextInput
              fullWidth
              key={config as any}
              disabled={busy}
              value={config?.maxCpu}
              onUpdate={(t) =>
                setConfig({
                  ...((config as any) || {}),
                  maxCpu: t,
                })
              }
              label="CPU"
              helperText="Limit the amount of CPU in terms of Millicores. I.e. 500m for 0.5vCPU."
            />
            <TextInput
              fullWidth
              key={config as any}
              disabled={busy}
              value={config?.maxMemory}
              onUpdate={(t) =>
                setConfig({
                  ...((config as any) || {}),
                  maxMemory: t,
                })
              }
              label="Memory"
              helperText="Limit the amount of memory in bytes, or mebibytes (Mi). "
            />
          </Stack>
          <Alert color="warning">
            Remember: Pods will never exceed the specified hard limit. Make sure
            to leave some flexability in the specifications.
          </Alert>
          <Button
            onClick={saveConfig}
            disabled={busy}
            variant="contained"
            sx={{ alignSelf: "end" }}
            size="large"
          >
            Save
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
