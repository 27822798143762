import { useEffect, useState } from "react";
import { DialogProps } from "./DialogProps";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import {
  ApproveWaitlistCustomer,
  DeleteCustomer,
  DisableCustomer,
} from "../core/rest";
import { enqueueSnackbar } from "notistack";
import { Warning } from "@mui/icons-material";
import { Customer } from "@announcely/models";
import moment from "moment";
import TextInput from "../components/TextInput";

export default function DeleteUserDialog(props: DialogProps<Customer>) {
  const [busy, setBusy] = useState(false);
  const [checked, setChecked] = useState(false);
  const [reasons, setReasons] = useState("");

  async function deleteUser() {
    try {
      setBusy(true);
      // Delete
      await DeleteCustomer(props.data?.uid!, reasons);
      enqueueSnackbar("User has been deleted.", { variant: "success" });
      props.closeHandler();
    } catch (err: any) {
      enqueueSnackbar("Error approving customer, please try again.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {}, []);

  return (
    <Dialog open fullWidth onClose={props.closeHandler}>
      <DialogTitle>
        <Typography>Delete User {props.data?.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing="18px">
          <Typography>
            Are you sure you want to delete the account for user{" "}
            {props.data?.name} with email <b>{props.data?.email}</b>?
          </Typography>
          <Alert color="warning" variant="outlined" icon={<Warning />}>
            The user last signed in on{" "}
            <b>
              {moment(props.data?.lastSignIn).format("MMM DD, YYYY HH:mm:ss")}
            </b>
          </Alert>
          <TextInput
            value={reasons}
            onUpdate={(t) => setReasons(t)}
            multiline
            rows={8}
            label="Reasons"
            placeholder="Please provide any reasons for disabling this user account."
          />
          <Alert color="error" variant="outlined" icon={<Warning />}>
            The action will delete the user from firebase and their relevant
            customer information from Stripe and local database. If the user has
            any bot running, it will also be deleted permanently. This action
            cannot be undone.
          </Alert>
          <Alert color="error" variant="outlined" icon={<Warning />}>
            The associated email address cannot be used with the platform again.
          </Alert>
        </Stack>
        <Stack direction={"row"} sx={{ mt: "10px" }}>
          <Checkbox onChange={(c) => setChecked(c.target.checked)} />
          <Typography>
            I have read and understood the consequences of this action, and I
            wish to proceed.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.closeHandler}
          disabled={busy}
          variant="text"
          sx={{ alignSelf: "end" }}
          size="large"
        >
          Nevermind
        </Button>
        <Button
          onClick={deleteUser}
          disabled={busy || !checked}
          color="error"
          variant="contained"
          sx={{ alignSelf: "end" }}
          size="large"
        >
          Yes, Delete User
        </Button>
      </DialogActions>
    </Dialog>
  );
}
