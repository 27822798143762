import { useEffect, useState } from "react";
import { DialogProps } from "./DialogProps";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { ApproveWaitlistCustomer } from "../core/rest";
import { enqueueSnackbar } from "notistack";
import { Warning } from "@mui/icons-material";
import { Customer } from "@announcely/models";
import moment from "moment";

export default function ApproveUserDialog(props: DialogProps<Customer>) {
  const [busy, setBusy] = useState(false);

  async function approve() {
    try {
      setBusy(true);
      await ApproveWaitlistCustomer(props.data?.uid!);
      enqueueSnackbar("User has been approved.", { variant: "success" });
      props.closeHandler();
    } catch (err: any) {
      enqueueSnackbar("Error approving customer, please try again.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {}, []);

  return (
    <Dialog open fullWidth onClose={props.closeHandler}>
      <DialogTitle>
        <Typography>Approve {props.data?.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing="18px">
          <Typography>
            Approve user {props.data?.name} with email{" "}
            <b>{props.data?.email}</b>?
          </Typography>
          <Alert color="info" variant="outlined" icon={<Warning />}>
            The user was registered on{" "}
            <b>
              {moment(props.data?.dateRegistered).format(
                "MMM DD, YYYY HH:mm:ss"
              )}
            </b>
          </Alert>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.closeHandler}
          disabled={busy}
          variant="text"
          sx={{ alignSelf: "end" }}
          size="large"
        >
          Nevermind
        </Button>
        <Button
          onClick={approve}
          disabled={busy}
          variant="contained"
          sx={{ alignSelf: "end" }}
          size="large"
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
}
