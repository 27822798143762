import React, { useEffect } from "react";
import { getAuth } from "firebase/auth";

/**
 * Renders children only if the user is logged in. Otherwise navigates to the /login (or specified redirectTo) page.
 * @returns
 */
export default function ProtectedRoute(props: {
	redirectTo?: string;
	children: any;
	fallbackChildren: any;
}) {
	//const user = FbAuth.currentUser;
	const [user, setUser] = React.useState<any>();
	useEffect(() => {
		const unsub = getAuth().onAuthStateChanged((usr) => {
			if (usr) {
				// we are logged in
				setUser(usr);
				console.log("Logged in.");
			} else {
				setUser(null);
				console.log("Logged out.");
			}
		});
		return unsub;
	}, []);

	if (!user) {
		return <>{props.fallbackChildren}</>;
	}
	return <>{props.children}</>;
}
