import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ThemeManager from "./providers/ThemeManager";
import { SnackbarProvider } from "notistack";
import AppRoutes from "./Routes";
import { Stack } from "@mui/material";
import ModalProvider from "mui-modal-provider";

export default function App() {
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<ThemeManager>
				<SnackbarProvider
					anchorOrigin={{ horizontal: "right", vertical: "top" }}
				>
					<ModalProvider>
						<Stack sx={{ width: "100vw", height: "100vh" }}>
							<AppRoutes />
						</Stack>
					</ModalProvider>
				</SnackbarProvider>
			</ThemeManager>
		</LocalizationProvider>
	);
}
