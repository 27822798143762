import React from "react";
import { Navigate, Routes } from "react-router";
import { HashRouter as Router, Route } from "react-router-dom";
import ProtectedRoute from "./providers/ProtectedRoute";
import PageLogin from "./pages/login";
import PagePortal from "./pages/portal";
import SplashScreen from "./components/SplashScreen";

export default function AppRoutes() {
	return (
		<SplashScreen>
			<Router basename="/">
				<Routes>
					{/* The unprotected routes  */}
					<Route path="/login" element={<PageLogin />} />
					<Route path="/verify" />

					{/* Protected routes  */}
					<Route
						path="/portal/*"
						element={
							<ProtectedRoute fallbackChildren={<PageLogin />}>
								<PagePortal />
							</ProtectedRoute>
						}
					/>
					<Route path="*" element={<Navigate to={"/portal/"} />} />
				</Routes>
			</Router>
		</SplashScreen>
	);
}
