const VERSION = "v1.1.1";
const REVISION = "October 10, 2023";
const prod = {
  version: VERSION,
  revision: REVISION,
  api: "https://console.announcely.app",
  firebase: {
    apiKey: "AIzaSyCr9lkGmihek-F0c7_BfacrjzCkg_0VaYo",
    authDomain: "announcely-app.firebaseapp.com",
    projectId: "announcely-app",
    storageBucket: "announcely-app.appspot.com",
    messagingSenderId: "761175836664",
    appId: "1:761175836664:web:14e1b97386520d5193780d",
    measurementId: "G-LGTQZ1BYE1",
  },
};
const debug = {
  version: VERSION,
  revision: REVISION,
  api: "http://127.0.0.1:81",
  // Change to debug
  firebase: {
    apiKey: "AIzaSyBBbknLkDFqm_MhBQFzZYgPQf2zd1d7e-c",
    authDomain: "announcely-debug.firebaseapp.com",
    projectId: "announcely-debug",
    storageBucket: "announcely-debug.appspot.com",
    messagingSenderId: "348302108527",
    appId: "1:348302108527:web:a8bea71d65c0f37d6faa9a",
  },
};

const ANN_DEBUG = process.env.REACT_APP_ANN_DEBUG === "true";

export const AppConfig = ANN_DEBUG ? debug : prod;
