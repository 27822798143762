import { useEffect, useState } from "react";
import { DialogProps } from "./DialogProps";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import TextInput from "../components/TextInput";
import { AddCoupon } from "../core/rest";
import { enqueueSnackbar } from "notistack";
import { Numbers, Percent } from "@mui/icons-material";
import ComboBox, { ComboBoxItem } from "../components/ComboBox";
import { StripeCoupon } from "@announcely/models";
import { DateTimePicker } from "@mui/x-date-pickers";

export default function AddCouponDialog(props: DialogProps<any>) {
  const [name, setName] = useState("");
  const [currency, setCurrency] = useState("");
  const [amountOff, setAmountOff] = useState(0);
  const [percentOff, setPercentOff] = useState(0);
  const [amountUnit, setAmountUnit] = useState<"percent" | "amount">("percent");
  // For duration=repeating
  const [durationMonths, setDurationMonths] = useState(0);
  const [duration, setDuration] = useState<ComboBoxItem>();
  const [durations, setDurations] = useState<ComboBoxItem[]>([]);
  const [maxUse, setMaxUse] = useState(0);
  const [expiry, setExpiry] = useState<moment.Moment | null>();

  const [busy, setBusy] = useState(false);

  const theme = useTheme();

  function IsInputValid() {
    if (!name) return false;
    if (!duration || !duration.object) return false;
    if (duration.object === "repeating" && !durationMonths) return false;
    if (!currency) return false;
    if (amountUnit === "amount" && !amountOff) return false;
    if (amountUnit === "percent" && !percentOff) return false;
    return true;
  }

  async function saveConfig() {
    setBusy(true);
    try {
      // Make sure inputs are valid.
      if (IsInputValid()) {
        // Add
        const coup: Partial<StripeCoupon> = {
          currency: currency,
        };
        if (amountUnit === "amount") coup.amount_off = amountOff;
        if (amountUnit === "percent") coup.percent_off = percentOff;
        coup.duration = duration!.object;
        if (duration?.object === "repeating")
          coup.duration_in_months = durationMonths;
        coup.name = name;
        if (maxUse) coup.max_redemptions = maxUse;
        if (expiry) coup.redeem_by = expiry.unix();

        await AddCoupon(coup);
        enqueueSnackbar("Coupon added successfully.", { variant: "success" });
        props.closeHandler();
      }
    } catch (err: any) {
      enqueueSnackbar("Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    const _durations: ComboBoxItem[] = [];
    _durations.push({
      index: _durations.length,
      name: "Once",
      object: "once",
    });
    _durations.push({
      index: _durations.length,
      name: "Months",
      object: "repeating",
    });
    _durations.push({
      index: _durations.length,
      name: "Forever",
      object: "forever",
    });
    setDurations(_durations);
  }, []);

  return (
    <Dialog open fullWidth onClose={props.closeHandler}>
      <DialogTitle>
        <Typography>Create a Coupon</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing="18px">
          <TextInput
            disabled={busy}
            value={name}
            onUpdate={(t) => setName(t)}
            label="Name"
            helperText="The name or coupon code. Must be unique."
          />

          <Stack>
            <Typography>Duration</Typography>
            <ComboBox
              value={duration?.index}
              values={durations}
              label="Duration"
              default={-1}
              placeholder="Select the duration..."
              required
              changed={(r) => setDuration(r)}
            />
          </Stack>

          {/* If duration is months, we ask how many...  */}
          {duration?.object === "repeating" && (
            <TextInput
              disabled={busy}
              value={durationMonths || ""}
              onUpdate={(t) => setDurationMonths(parseInt(t))}
              label="Number of Months"
              helperText="How many months should the coupon be in effect for"
            />
          )}

          <TextInput
            disabled={busy}
            value={currency}
            onUpdate={(t) => setCurrency(t)}
            label="Currency code"
          />
          <Typography
            sx={{ fontSize: "12px", p: 0, m: 0, lineHeight: 0, px: "14px" }}
          >
            Enter the 3-letter{" "}
            <Link target="_blank" href="https://stripe.com/docs/currencies">
              ISO code for the currency
            </Link>
            .
          </Typography>
          {currency && (
            <Stack>
              <Typography>Discount Unit</Typography>
              <RadioGroup
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <FormControlLabel
                  disabled={busy}
                  control={
                    <Radio
                      disableRipple
                      checked={amountUnit === "percent"}
                      onChange={(e) =>
                        setAmountUnit(
                          e.currentTarget.checked ? "percent" : "amount"
                        )
                      }
                      icon={<Percent />}
                      checkedIcon={<Percent />}
                    />
                  }
                  label="Percentage"
                  sx={{
                    flex: 1,
                    height: "60px",
                    border:
                      amountUnit === "percent"
                        ? "1px solid " + theme.palette.primary.main
                        : "1px solid #AAA",
                    py: "4px",
                    px: "18px",
                    borderRadius: "15px",
                    m: 0,
                  }}
                />
                <FormControlLabel
                  disabled={busy}
                  control={
                    <Radio
                      disableRipple
                      checked={amountUnit === "amount"}
                      onChange={(e) =>
                        setAmountUnit(
                          e.currentTarget.checked ? "amount" : "percent"
                        )
                      }
                      icon={<Numbers />}
                      checkedIcon={<Numbers />}
                    />
                  }
                  label="Amount"
                  sx={{
                    flex: 1,
                    height: "60px",
                    border:
                      amountUnit === "amount"
                        ? "1px solid " + theme.palette.primary.main
                        : "1px solid #AAA",
                    py: "4px",
                    px: "18px",
                    borderRadius: "15px",
                    m: 0,
                  }}
                />
              </RadioGroup>
            </Stack>
          )}
          {currency && amountUnit === "amount" && (
            <TextInput
              disabled={busy}
              value={amountOff || ""}
              onUpdate={(t) => setAmountOff(parseInt(t))}
              label={"Amount in " + currency.toUpperCase()}
            />
          )}
          {currency && amountUnit === "percent" && (
            <TextInput
              disabled={busy}
              value={percentOff || ""}
              onUpdate={(t) => setPercentOff(parseInt(t))}
              label="Percent"
            />
          )}
          <TextInput
            disabled={busy}
            value={maxUse || ""}
            onUpdate={(t) => setMaxUse(parseInt(t))}
            label="Max Uses"
            helperText="Leave empty for unlimited uses."
          />
          <DateTimePicker
            disablePast
            label="Expiry"
            value={expiry}
            onChange={(e) => setExpiry(e)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.closeHandler}
          disabled={busy}
          variant="text"
          sx={{ alignSelf: "end" }}
          size="large"
        >
          Cancel
        </Button>
        <Button
          onClick={saveConfig}
          disabled={busy || !IsInputValid()}
          variant="contained"
          sx={{ alignSelf: "end" }}
          size="large"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
