const LOGO_LIGHT_VERTICAL = require("./brand/vertical/light.png");
const LOGO_DARK_VERTICAL = require("./brand/vertical/dark.png");
const LOGO_DARK_BLUE_VERTICAL = require("./brand/vertical/blue_dark.png");
const LOGO_LIGHT_BLUE_VERTICAL = require("./brand/vertical/blue_light.png");

const LOGO_LIGHT_HORIZONTAL = require("./brand/horizontal/light.png");
const LOGO_DARK_HORIZONTAL = require("./brand/horizontal/dark.png");
const LOGO_DARK_BLUE_HORIZONTAL = require("./brand/horizontal/blue_dark.png");
const LOGO_LIGHT_BLUE_HORIZONTAL = require("./brand/horizontal/blue_light.png");

const IC_AVATAR = require("./ui/avatar.png");
const IC_CARD = require("./ui/card.png");

export {
	LOGO_LIGHT_VERTICAL,
	LOGO_DARK_VERTICAL,
	LOGO_DARK_BLUE_VERTICAL,
	LOGO_LIGHT_BLUE_VERTICAL,
	LOGO_LIGHT_HORIZONTAL,
	LOGO_DARK_HORIZONTAL,
	LOGO_DARK_BLUE_HORIZONTAL,
	LOGO_LIGHT_BLUE_HORIZONTAL,
	IC_AVATAR,
	IC_CARD,
};
