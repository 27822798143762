import { Backdrop, CircularProgress, Stack } from "@mui/material";
import React from "react";

export default function LoadingOverlay(props: { title: string; hint: string }) {
	return (
		<Backdrop
			sx={{
				color: "#fff",
				zIndex: (theme: any) => theme.zIndex.drawer + 1,
				display: "flex",
				flexDirection: "column",
			}}
			open={true}
		>
			<Stack
				flexGrow="1"
				direction="column"
				spacing={2}
				justifyContent="center"
				alignItems="center"
			>
				{/* <img
					src={LOGO_DASHBOARD}
					alt="Community Juice Logo"
					width={"128"}
				/> */}
				<CircularProgress color="inherit" />
				<div>{props.title}</div>
			</Stack>
			<Stack
				my={5}
				direction="row"
				spacing={2}
				justifyContent="end"
				alignItems="center"
			>
				<em>{props.hint}</em>
			</Stack>
		</Backdrop>
	);
}
