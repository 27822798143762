import { Paper, Stack, Typography, useTheme, Button } from "@mui/material";
import { useState } from "react";
import { LOGO_LIGHT_VERTICAL } from "../assets";
import { FbAuth } from "../core/firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

export default function PageLogin() {
  const [busy, setBusy] = useState(false);

  const theme = useTheme();

  async function continueWithGoogle() {
    setBusy(false);
    try {
      setBusy(true);
      await signInWithPopup(FbAuth, new GoogleAuthProvider());
    } catch (err: any) {
      console.log("Error signing in.");
      console.log(err);
    }
    setBusy(false);
  }

  return (
    <Stack
      sx={{
        height: "100vh",
        width: "100vw",
        background: theme.palette.background.default,
      }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Stack justifyContent={"center"} alignItems={"center"} spacing={"14px"}>
        {/* The logo  */}
        <img
          src={LOGO_LIGHT_VERTICAL}
          alt="Announcely Logo"
          style={{ height: "196px" }}
        />
        <Typography fontSize={"24px"}>Management Console</Typography>
        {/* The paper container  */}
        <Paper elevation={1} sx={{ p: "16px", minWidth: "300px" }}>
          <Stack spacing={"14px"}>
            <Typography fontSize={"24px"}>Sign in</Typography>
            <Typography>
              Sign in with Google. <br />
            </Typography>
            <Button
              variant="outlined"
              sx={{ height: "44px" }}
              disabled={busy}
              onClick={continueWithGoogle}
            >
              Continue with Google
            </Button>
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
}
