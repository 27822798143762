import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { aCurrentSection } from "../states/ui";
import { enqueueSnackbar } from "notistack";
import { AmcAdmin } from "@announcely/models";
import moment from "moment";
import { DeleteAdmin, GetAdmins } from "../core/rest";
import { Add, Delete, Refresh } from "@mui/icons-material";
import { useModal } from "mui-modal-provider";
import AddAdminDialog from "../dialogs/AddAdmin";

export default function SectionAdmins() {
  const [, setCurrentSection] = useRecoilState(aCurrentSection);
  const [admins, setAdmins] = useState<AmcAdmin[]>([]);
  const [busy, setBusy] = React.useState(false);
  const { showModal } = useModal();

  async function load() {
    try {
      setBusy(true);
      const admins = await GetAdmins();
      setAdmins(admins || []);
    } catch (err: any) {
      enqueueSnackbar("Error loading admins, please try again.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  function showAddDialog() {
    const modal = showModal(AddAdminDialog, {
      closeHandler() {
        modal.destroy();
        load();
      },
    });
  }

  useEffect(() => {
    setCurrentSection("Administrators");
    load();
  }, []);
  return (
    <Stack sx={{ overflow: "hidden" }}>
      <Stack
        flex={1}
        sx={{ p: "24px", pt: "48px", overflow: "hidden" }}
        spacing={"8px"}
      >
        <Stack direction={"row"} spacing="12px">
          <Button
            endIcon={<Refresh />}
            variant="contained"
            disabled={busy}
            onClick={load}
          >
            Refresh
          </Button>
          <Button
            variant="outlined"
            endIcon={<Add />}
            disabled={busy}
            onClick={showAddDialog}
          >
            Add
          </Button>
        </Stack>
        {/* The table for history */}
        <TableContainer sx={{ overflow: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left">Email</TableCell>
                <TableCell align="center">Added on</TableCell>
                <TableCell align="center">Last Sign In</TableCell>
                <TableCell align="right">Role</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admins.map((admin) => (
                <TableRow>
                  <TableCell align="left">{admin.email}</TableCell>
                  <TableCell align="center">
                    {moment(admin.created).format("MMM DD, YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    {admin.lastSignIn
                      ? moment(admin.lastSignIn).fromNow()
                      : "-"}
                  </TableCell>
                  <TableCell align="right">{admin.role}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      title="Delete the admin."
                      onClick={async () => {
                        try {
                          await DeleteAdmin(admin.email);
                          enqueueSnackbar("Admin removed.", {
                            variant: "success",
                          });
                          load();
                        } catch (err: any) {
                          enqueueSnackbar(
                            "Error deleting admin. ERROR: " + err.message,
                            { variant: "error" }
                          );
                        }
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
}
