import { useEffect, useState } from "react";
import { Stack, Typography, IconButton, Tooltip } from "@mui/material";
import SearchField from "./SearchField";
import { useRecoilState } from "recoil";
import { aCurrentSection } from "../states/ui";
import { PowerSettingsNew } from "@mui/icons-material";
import { signOut } from "firebase/auth";
import { FbAuth } from "../core/firebase";

export default function AppBar() {
  const [currentSection] = useRecoilState(aCurrentSection);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  async function loadProfile() {
    try {
      setFullName(FbAuth.currentUser?.displayName || "");
      setEmail(FbAuth.currentUser?.email || "");
    } catch (err: any) {
      // Error loading profile.
      console.log("Error loading profile.");
      console.log(err);
    }
  }

  async function logout() {
    signOut(FbAuth);
  }

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <Stack
      justifyContent={"space-between"}
      alignItems={"center"}
      direction={"row"}
      sx={{ p: "24px" }}
    >
      {/* The title of section  */}
      <Typography>{currentSection}</Typography>
      {/* The search and profile */}
      <Stack direction={"row"} alignItems={"center"} spacing={"24px"}>
        <Typography>{fullName || email}</Typography>
        <Tooltip title="Logout">
          <IconButton onClick={logout}>
            <PowerSettingsNew fontSize={"large"} color="error" />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
