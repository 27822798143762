import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { aCurrentSection } from "../states/ui";
import { Grid, Stack, Typography } from "@mui/material";
import AnalyticsCard from "../components/AnalyticsCard";
import { People, Telegram, Today, WhatsApp } from "@mui/icons-material";
import { useSnackbar } from "notistack";

export default function SectionDashboard() {
	const [, setCurrentSection] = useRecoilState(aCurrentSection);
	const [busy, setBusy] = React.useState(false);
	const { enqueueSnackbar } = useSnackbar();

	async function load() {
		try {
		} catch (err: any) {
			enqueueSnackbar(
				"Error loading data. Please see logs for details.",
				{ variant: "error" }
			);
			console.log("ERROR loading data.");
			console.log(err);
		}
	}

	useEffect(() => {
		setCurrentSection("Dashboard");
		load();
	}, []);

	return (
		<Grid
			container
			columns={4}
			sx={{ p: "24px" }}
			spacing={"24px"}
			columnSpacing={"28px"}
			justifyContent={"flex-start"}
		>
			<Grid item xs={4}>
				<Stack>
					<Typography fontFamily={"28px"}>Subscribers</Typography>
				</Stack>
			</Grid>
			<Grid item>
				<Stack>
					<AnalyticsCard
						label="Subscribers"
						color="#F88"
						icon={<People />}
					/>
				</Stack>
			</Grid>
			<Grid item>
				<Stack>
					<AnalyticsCard
						label="Telegram"
						color="#88F"
						icon={<Telegram />}
					/>
				</Stack>
			</Grid>
			<Grid item>
				<Stack>
					<AnalyticsCard
						label="WhatsApp"
						color="#88FF94"
						icon={<WhatsApp />}
					/>
				</Stack>
			</Grid>

			<Grid item>
				<Stack>
					<AnalyticsCard
						label="Today"
						color="#88F"
						icon={<Today />}
					/>
				</Stack>
			</Grid>
		</Grid>
	);
}
