import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { aCurrentSection } from "../states/ui";
import { Button, Grid, Paper, Stack, Switch, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import TextInput from "../components/TextInput";
import { AnnouncelyPricing } from "@announcely/models";
import { GetConfig, SetConfig } from "../core/rest";

export default function SectionConfigs() {
  const [, setCurrentSection] = useRecoilState(aCurrentSection);
  const [busy, setBusy] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [mailgunDomain, setMailgunDomain] = useState("");
  const [mailgun, setMailgun] = useState("");
  const [pricing, setPricing] = useState<AnnouncelyPricing>();
  const [products, setProducts] = useState<{
    aio: string;
    telegram: string;
  }>();
  const [verificationContinueUrl, setVerificationContinueUrl] = useState("");
  const [checkoutContinueUrl, setCheckoutContinueUrl] = useState("");
  const [dashboardUrl, setDashboardUrl] = useState("");
  const [trialDays, setTrialDays] = useState(0);
  const [enableTrial, setEnableTrial] = useState(false);
  const [notificationEmail, setNotificationEmail] = useState("");

  const [editProps, setEditProps] = useState<{
    mailgun: boolean;
    products: boolean;
    pricing: boolean;
    redirects: boolean;
    notifications: boolean;
  }>();

  function setEditMode(props: {
    mailgun?: boolean;
    products?: boolean;
    pricing?: boolean;
    redirects?: boolean;
    notifications?: boolean;
  }) {
    setEditProps({
      ...(editProps as any),
      ...(props as any),
    });
  }

  async function load() {
    setBusy(true);
    try {
      // Load all configs.
      const _stripe = await GetConfig("mailgun_domain");
      setMailgunDomain(_stripe || "");
      const _mailgun = await GetConfig("mailgun");
      setMailgun(_mailgun || "");
      const _pricing = await GetConfig("pricing");
      setPricing(_pricing || {});
      const _prods = await GetConfig("products");
      setProducts(_prods);
      const _vcu = await GetConfig("verification_continue_url");
      setVerificationContinueUrl(_vcu);
      const _cu = await GetConfig("checkout_continue_url");
      setCheckoutContinueUrl(_cu);
      const _du = await GetConfig("dashboard_url");
      setDashboardUrl(_du);
      const _trial = await GetConfig("trial");
      setTrialDays(_trial);
      setEnableTrial(parseInt(_trial) > 0);
      const _ne = await GetConfig("notification_email");
      setNotificationEmail(_ne);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please see logs for details.", {
        variant: "error",
      });
      console.log("ERROR loading data.");
      console.log(err);
    }
    setBusy(false);
  }

  async function saveNotificationEmail() {
    setBusy(true);
    try {
      await SetConfig("notification_email", notificationEmail);
      setEditMode({ notifications: false });
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please see logs for details.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function saveMailgun() {
    setBusy(true);
    try {
      await SetConfig("mailgun", mailgun);
      await SetConfig("mailgun_domain", mailgunDomain);
      setEditMode({ mailgun: false });
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please see logs for details.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function savePrices() {
    setBusy(true);
    try {
      await SetConfig("pricing", pricing);
      await SetConfig("trial", enableTrial ? trialDays : 0);
      setEditMode({ pricing: false });
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please see logs for details.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function saveProducts() {
    setBusy(true);
    try {
      await SetConfig("products", products);
      setEditMode({ products: false });
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please see logs for details.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function saveRedirects() {
    setBusy(true);
    try {
      await SetConfig("dashboard_url", dashboardUrl);
      await SetConfig("verification_continue_url", verificationContinueUrl);
      await SetConfig("checkout_continue_url", checkoutContinueUrl);
      setEditMode({ redirects: false });
    } catch (err: any) {
      enqueueSnackbar("Error saving data. Please see logs for details.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    setCurrentSection("Configuration");
    load();
  }, []);

  return (
    <Grid
      container
      columns={6}
      sx={{ p: "24px", overflowX: "hidden", overflowY: "auto" }}
      spacing={"24px"}
      columnSpacing={"28px"}
      justifyContent={"flex-start"}
    >
      {/* Mailgun Settings  */}
      <Grid item xs={6}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontFamily={"28px"}>Mailgun</Typography>
          {!editProps?.mailgun && (
            <Button
              disabled={busy}
              onClick={() => setEditMode({ mailgun: true })}
            >
              Edit
            </Button>
          )}
          {editProps?.mailgun && (
            <Button
              disabled={busy}
              onClick={saveMailgun}
              variant="contained"
              sx={{ alignSelf: "end" }}
            >
              Save
            </Button>
          )}
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Paper sx={{ px: "20px", py: "18px" }} elevation={1}>
          <Stack spacing="8px">
            <TextInput
              disabled={!editProps?.mailgun}
              value={mailgunDomain}
              onUpdate={(c) => setMailgunDomain(c)}
              label="Mailgun Domain"
              placeholder="Enter Mailgun Domain..."
            />
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper sx={{ px: "20px", py: "18px" }} elevation={1}>
          <Stack spacing="8px">
            <TextInput
              disabled={!editProps?.mailgun}
              value={mailgun}
              onUpdate={(c) => setMailgun(c)}
              label="Mailgun API"
              placeholder="Enter your Mailgun API key..."
            />
          </Stack>
        </Paper>
      </Grid>

      {/* Products Settings  */}
      <Grid item xs={6}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontFamily={"28px"}>Products</Typography>
          {!editProps?.products && (
            <Button
              disabled={busy}
              onClick={() => setEditMode({ products: true })}
            >
              Edit
            </Button>
          )}
          {editProps?.products && (
            <Button
              disabled={busy}
              onClick={saveProducts}
              variant="contained"
              sx={{ alignSelf: "end" }}
            >
              Save
            </Button>
          )}
        </Stack>
      </Grid>

      <Grid item xs={2}>
        <Paper sx={{ px: "20px", py: "18px" }} elevation={1}>
          <Stack spacing="8px">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>Premium</Typography>
            </Stack>
            <TextInput
              key={products?.aio}
              disabled={!editProps?.products}
              value={products?.aio}
              onUpdate={(c) => setProducts({ ...(products as any), aio: c })}
              label="Stripe Product ID"
              placeholder="Enter product id.."
            />
          </Stack>
        </Paper>
      </Grid>

      <Grid item xs={2}>
        <Paper sx={{ px: "20px", py: "18px" }} elevation={1}>
          <Stack spacing="8px">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>Telegram</Typography>
            </Stack>
            <TextInput
              key={products?.telegram}
              disabled={!editProps?.products}
              value={products?.telegram}
              onUpdate={(c) =>
                setProducts({ ...(products as any), telegram: c })
              }
              label="Stripe Product ID"
              placeholder="Enter product id.."
            />
          </Stack>
        </Paper>
      </Grid>

      {/* Pricing Settings  */}
      <Grid item xs={6}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontFamily={"28px"}>Pricing</Typography>
          {!editProps?.pricing && (
            <Button
              disabled={busy}
              onClick={() => setEditMode({ pricing: true })}
            >
              Edit
            </Button>
          )}
          {editProps?.pricing && (
            <Button
              disabled={busy}
              onClick={savePrices}
              variant="contained"
              sx={{ alignSelf: "end" }}
            >
              Save
            </Button>
          )}
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <Paper sx={{ px: "20px", py: "18px" }} elevation={1}>
          <Stack direction={"row"} spacing="24px">
            <Stack flex={1} spacing="8px">
              <Typography>Yearly</Typography>
              <TextInput
                key={pricing?.yearly?.aio}
                label="Premium Price ID"
                placeholder="Enter price ID..."
                disabled={!editProps?.pricing}
                value={pricing?.yearly?.aio}
                onUpdate={(c) =>
                  setPricing({
                    monthly: pricing?.monthly as any,
                    yearly: {
                      aio: c,
                      telegram: pricing?.yearly?.telegram || "",
                    },
                  })
                }
              />
              <TextInput
                key={pricing?.yearly?.telegram}
                label="Telegram Price ID"
                placeholder="Enter price ID..."
                disabled={!editProps?.pricing}
                value={pricing?.yearly?.telegram}
                onUpdate={(c) =>
                  setPricing({
                    monthly: pricing?.monthly as any,
                    yearly: {
                      aio: pricing?.yearly?.aio || "",
                      telegram: c,
                    },
                  })
                }
              />
            </Stack>
            <Stack flex={1} spacing="8px">
              <Typography>Monthly</Typography>
              <TextInput
                key={pricing?.monthly?.aio}
                disabled={!editProps?.pricing}
                value={pricing?.monthly?.aio}
                onUpdate={(c) =>
                  setPricing({
                    monthly: {
                      aio: c,
                      telegram: pricing?.monthly?.telegram || "",
                    },
                    yearly: pricing?.yearly as any,
                  })
                }
                label="Premium Price ID"
                placeholder="Enter price ID..."
              />
              <TextInput
                key={pricing?.monthly?.telegram}
                disabled={!editProps?.pricing}
                value={pricing?.monthly?.telegram}
                onUpdate={(c) =>
                  setPricing({
                    monthly: {
                      aio: pricing?.monthly?.aio || "",
                      telegram: c,
                    },
                    yearly: pricing?.yearly as any,
                  })
                }
                label="Telegram Price ID"
                placeholder="Enter price ID..."
              />
            </Stack>
          </Stack>
        </Paper>
      </Grid>

      <Grid item xs={3}>
        <Paper sx={{ px: "20px", py: "18px" }} elevation={1}>
          <Stack spacing="8px">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>Enable Trial Period</Typography>
              <Switch
                disabled={!editProps?.pricing}
                onChange={(c) => setEnableTrial(c.currentTarget.checked)}
              />
            </Stack>
            <TextInput
              disabled={!editProps?.pricing}
              value={trialDays ? trialDays : ""}
              onUpdate={(c) => setTrialDays(parseInt(c))}
              label="Number of Days"
              placeholder="Enter number of days for trial..."
            />
          </Stack>
        </Paper>
      </Grid>

      {/* Redirects  and Links Settings*/}
      <Grid item xs={6}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontFamily={"28px"}>Redirects & Links</Typography>
          {!editProps?.redirects && (
            <Button
              disabled={busy}
              onClick={() => setEditMode({ redirects: true })}
            >
              Edit
            </Button>
          )}
          {editProps?.redirects && (
            <Button
              disabled={busy}
              onClick={saveRedirects}
              variant="contained"
              sx={{ alignSelf: "end" }}
            >
              Save
            </Button>
          )}
        </Stack>
      </Grid>

      <Grid item xs={2}>
        <Paper sx={{ px: "20px", py: "18px" }} elevation={1}>
          <Stack spacing="8px">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>Verification Continue URL</Typography>
            </Stack>
            <TextInput
              disabled={!editProps?.redirects}
              value={verificationContinueUrl}
              onUpdate={(c) => setVerificationContinueUrl(c)}
              label="Continue URL"
              placeholder="Continue URL"
              helperText="The link where users are navigated after verifying the account. "
            />
          </Stack>
        </Paper>
      </Grid>

      <Grid item xs={2}>
        <Paper sx={{ px: "20px", py: "18px" }} elevation={1}>
          <Stack spacing="8px">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>Dashboard URL</Typography>
            </Stack>
            <TextInput
              disabled={!editProps?.redirects}
              value={dashboardUrl}
              onUpdate={(c) => setDashboardUrl(c)}
              label="Dashboard URL"
              placeholder="Enter Dashboard URL.."
              helperText="The link sent to users in emails with 'Dashboard' button."
            />
          </Stack>
        </Paper>
      </Grid>

      <Grid item xs={2}>
        <Paper sx={{ px: "20px", py: "18px" }} elevation={1}>
          <Stack spacing="8px">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>Checkout Continue URL</Typography>
            </Stack>
            <TextInput
              disabled={!editProps?.redirects}
              value={checkoutContinueUrl}
              onUpdate={(c) => setCheckoutContinueUrl(c)}
              label="Continue URL"
              placeholder="Enter Continue URL.."
              helperText="The link where users are navigated after checkout from stripe."
            />
          </Stack>
        </Paper>
      </Grid>

      {/* Notifications and misc  */}
      {/* Mailgun Settings  */}
      <Grid item xs={6}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontFamily={"28px"}>Alerts & Notifications</Typography>
          {!editProps?.notifications && (
            <Button
              disabled={busy}
              onClick={() => setEditMode({ notifications: true })}
            >
              Edit
            </Button>
          )}
          {editProps?.notifications && (
            <Button
              disabled={busy}
              onClick={saveNotificationEmail}
              variant="contained"
              sx={{ alignSelf: "end" }}
            >
              Save
            </Button>
          )}
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Paper sx={{ px: "20px", py: "18px" }} elevation={1}>
          <Stack spacing="8px">
            <TextInput
              disabled={!editProps?.notifications}
              value={notificationEmail}
              onUpdate={(c) => setNotificationEmail(c)}
              label="Notifications Email"
              placeholder="Enter Email..."
              helperText="Default: announcely.app@gmail.com"
            />
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
}
