import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { aCurrentSection } from "../states/ui";
import { enqueueSnackbar } from "notistack";
import { AmcAdmin, StripeCoupon } from "@announcely/models";
import moment from "moment";
import { DeleteAdmin, DeleteCoupon, GetAdmins, GetCoupons } from "../core/rest";
import { Add, Delete, Refresh } from "@mui/icons-material";
import { useModal } from "mui-modal-provider";
import AddAdminDialog from "../dialogs/AddAdmin";
import AddCouponDialog from "../dialogs/AddCoupon";

export default function SectionCoupons() {
  const [, setCurrentSection] = useRecoilState(aCurrentSection);
  const [coupons, setCoupons] = useState<StripeCoupon[]>([]);
  const [busy, setBusy] = React.useState(false);
  const { showModal } = useModal();

  async function load() {
    try {
      setBusy(true);
      const cps = await GetCoupons();
      setCoupons(cps || []);
    } catch (err: any) {
      enqueueSnackbar("Error loading coupons, please try again.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  function showAddDialog() {
    const modal = showModal(AddCouponDialog, {
      closeHandler() {
        modal.destroy();
        load();
      },
    });
  }

  useEffect(() => {
    setCurrentSection("Coupons");
    load();
  }, []);
  return (
    <Stack sx={{ overflow: "hidden" }}>
      <Stack
        flex={1}
        sx={{ p: "24px", pt: "48px", overflow: "hidden" }}
        spacing={"8px"}
      >
        <Stack direction={"row"} spacing="12px">
          <Button
            endIcon={<Refresh />}
            variant="contained"
            disabled={busy}
            onClick={load}
          >
            Refresh
          </Button>
          <Button
            variant="outlined"
            endIcon={<Add />}
            disabled={busy}
            onClick={showAddDialog}
          >
            Create
          </Button>
        </Stack>
        {/* The table for history */}
        <TableContainer sx={{ overflow: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="center">Created</TableCell>
                <TableCell align="center">Use Count</TableCell>
                <TableCell align="center">Duration</TableCell>
                <TableCell align="center">Currency</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Expiry</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons.map((coup) => (
                <TableRow>
                  <TableCell align="left">{coup.name}</TableCell>
                  <TableCell align="center">
                    {moment.unix(coup.created).format("MMM DD, YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    {coup.times_redeemed +
                      (coup.max_redemptions
                        ? " / " + coup.max_redemptions
                        : "")}
                  </TableCell>
                  <TableCell align="center">
                    {coup.duration === "repeating"
                      ? `${coup.duration_in_months} months`
                      : coup.duration}
                  </TableCell>
                  <TableCell align="center">{coup.currency}</TableCell>
                  <TableCell align="center">
                    {coup.percent_off
                      ? `${coup.percent_off}%`
                      : `${coup.currency.toUpperCase()} ${coup.amount_off}`}
                  </TableCell>
                  <TableCell align="center">
                    {coup.valid
                      ? coup.redeem_by
                        ? moment.unix(coup.redeem_by).fromNow()
                        : "Never"
                      : "Expired"}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      title="Delete the admin."
                      onClick={async () => {
                        try {
                          await DeleteCoupon(coup.id);
                          enqueueSnackbar("Coupon removed.", {
                            variant: "success",
                          });
                          load();
                        } catch (err: any) {
                          enqueueSnackbar(
                            "Error deleting coupon. ERROR: " + err.message,
                            { variant: "error" }
                          );
                        }
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
}
