import axios from "axios";
import { AppConfig } from "../config";
import { FbAuth } from "./firebase";
import { AmcAdmin, Customer, StripeCoupon } from "@announcely/models";

export async function GetCustomers() {
  try {
    // Call /profile/ with POST method.
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.get(AppConfig.api + "/customers/", {
        headers: {
          Authorization: token,
        },
      });
      return resp.data as Customer[];
    }
  } catch (err: any) {
    console.log("Error getting customers.");
    console.log(err);
    throw err;
  }
}

export async function GetWaitlistCustomers() {
  try {
    // Call /profile/ with POST method.
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.get(AppConfig.api + "/customers/waitlist", {
        headers: {
          Authorization: token,
        },
      });
      return resp.data as Customer[];
    }
  } catch (err: any) {
    console.log("Error getting customers.");
    console.log(err);
    throw err;
  }
}

export async function DisableCustomer(
  customerId: string,
  reason: string,
  sendReason: boolean
) {
  try {
    // Call /customers/disable with POST method.
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.post(
        AppConfig.api + "/customers/disable",
        {
          customer: customerId,
          reason: reason,
          notify: sendReason,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return resp.data;
    }
  } catch (err: any) {
    console.log("Error disabling customer.");
    console.log(err);
    throw err;
  }
}

export async function DeleteCustomer(customerId: string, reason: string) {
  try {
    // Call /customers/disable with POST method.
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.post(
        AppConfig.api + "/customers/delete",
        {
          customer: customerId,
          reason: reason,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return resp.data;
    }
  } catch (err: any) {
    console.log("Error deleting customer.");
    console.log(err);
    throw err;
  }
}

export async function ApproveWaitlistCustomer(customerId: string) {
  try {
    // Call /profile/ with POST method.
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.post(
        AppConfig.api + "/customers/approve",
        {
          customer: customerId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return resp.data;
    }
  } catch (err: any) {
    console.log("Error getting customers.");
    console.log(err);
    throw err;
  }
}

export async function GetAdmins() {
  try {
    // Call /admins/ with GET method.
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.get(AppConfig.api + "/admins/", {
        headers: {
          Authorization: token,
        },
      });
      return resp.data as AmcAdmin[];
    }
  } catch (err: any) {
    console.log("Error getting customers.");
    console.log(err);
    throw err;
  }
}

export async function GetCoupons() {
  try {
    // Call /coupons/ with GET method.
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.get(AppConfig.api + "/coupons/", {
        headers: {
          Authorization: token,
        },
      });
      return resp.data as StripeCoupon[];
    }
  } catch (err: any) {
    console.log("Error getting coupons.");
    console.log(err);
    throw err;
  }
}

export async function AddAdmin(email: string, role: string) {
  try {
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.post(
        AppConfig.api + "/admins/",
        {
          email: email,
          role: role,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return resp.data as any;
    }
  } catch (err: any) {
    console.log("Error saving data.");
    console.log(err);
    throw err;
  }
}

export async function AddCoupon(coupon: Partial<StripeCoupon>) {
  try {
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.post(AppConfig.api + "/coupons/", coupon, {
        headers: {
          Authorization: token,
        },
      });
      return resp.data as any;
    }
  } catch (err: any) {
    console.log("Error saving data.");
    console.log(err);
    throw err;
  }
}

export async function DeleteCoupon(id: string) {
  try {
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.post(
        AppConfig.api + "/coupons/delete",
        {
          id: id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return resp.data as any;
    }
  } catch (err: any) {
    console.log("Error saving data.");
    console.log(err);
    throw err;
  }
}

export async function DeleteAdmin(email: string) {
  try {
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.post(
        AppConfig.api + "/admins/delete",
        {
          email: email,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return resp.data as any;
    }
  } catch (err: any) {
    console.log("Error saving data.");
    console.log(err);
    throw err;
  }
}

export async function GetServices() {
  try {
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.get(AppConfig.api + "/services/", {
        headers: {
          Authorization: token,
        },
      });
      return resp.data as any[];
    }
  } catch (err: any) {
    console.log("Error getting services.");
    console.log(err);
    throw err;
  }
}

export async function GetConfig(key: string) {
  try {
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.get(AppConfig.api + "/configs/" + key + "/", {
        headers: {
          Authorization: token,
        },
      });
      return resp.data as any;
    }
  } catch (err: any) {
    console.log("Error getting configuration.");
    console.log(err);
    throw err;
  }
}

export async function SetConfig(key: string, value: any) {
  try {
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.post(
        AppConfig.api + "/configs/",
        { key: key, value: value },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return resp.data as any;
    }
  } catch (err: any) {
    console.log("Error sending configuration.");
    console.log(err);
    throw err;
  }
}

export async function UpdateService(uid: string) {
  try {
    const token = await FbAuth.currentUser?.getIdToken();
    if (token) {
      const resp = await axios.post(
        AppConfig.api + "/services/" + uid + "/update",
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return resp.data as any;
    }
  } catch (err: any) {
    console.log("Error sending configuration.");
    console.log(err);
    throw err;
  }
}
