import {
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { aCurrentSection } from "../states/ui";
import { useSnackbar } from "notistack";
import { Customer, SubscriptionStatus } from "@announcely/models";
import moment from "moment";
import { GetCustomers } from "../core/rest";
import OptionsGroup from "../components/OptionsGroup";
import {
  CheckSharp,
  DeleteForever,
  MoreVert,
  Search,
  StopCircle,
} from "@mui/icons-material";
import { useModal } from "mui-modal-provider";
import ApproveUserDialog from "../dialogs/ApproveDialog";
import DisableUserDialog from "../dialogs/DisableUserDialog";
import DeleteUserDialog from "../dialogs/DeleteDialog";
import SearchField from "../components/SearchField";
import TextInput from "../components/TextInput";

export default function SectionCustomers() {
  const [, setCurrentSection] = useRecoilState(aCurrentSection);
  const [customers, setCustomers] = React.useState<Customer[] | undefined>([]);
  const [busy, setBusy] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [filter, setFilter] = useState<
    "all" | "waitlisted" | "subscribed" | "disabled" | "deleted" | "custom"
  >("all");
  const [search, setSearch] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { showModal } = useModal();

  async function load() {
    try {
      setBusy(true);
      const custs = await GetCustomers();
      // Apply filter.
      switch (filter) {
        case "all": {
          setCustomers(custs);
          break;
        }
        case "subscribed": {
          // Only with subscriptions
          const subbed = custs?.filter(
            (c) =>
              !c.deleted &&
              !c.disabled &&
              c.billing &&
              c.billing.subscriptions &&
              c.billing.subscriptions.length > 0
          );
          setCustomers(subbed);
          break;
        }
        case "waitlisted": {
          // Only in waitlist
          const waiting = custs?.filter(
            (c) =>
              (c.isApproved === false || c.isApproved === undefined) &&
              !c.deleted &&
              !c.disabled
          );
          setCustomers(waiting);
          break;
        }
        case "deleted": {
          // Only in deleted
          const delets = custs?.filter((c) => c.deleted);
          setCustomers(delets);
          break;
        }
        case "disabled": {
          // Only in waitlist
          const delets = custs?.filter((c) => c.disabled && !c.deleted);
          setCustomers(delets);
          break;
        }
        case "custom": {
          // Filter based on sear ch text
          const filts = custs?.filter(
            (c) => c.name.includes(search) || c.email.includes(search)
          );
          setCustomers(filts);
          break;
        }
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading customers, please try again.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  function GetStatus(customer: Customer) {
    if (customer.deleted) {
      return <Typography color="error">Deleted</Typography>;
    }
    if (customer.disabled) {
      return <Typography color="gold">Disabled</Typography>;
    }
    if (
      customer.billing &&
      customer.billing.subscriptions &&
      customer.billing.subscriptions.length > 0
    ) {
      return <Typography color="#4488FF">Subscribed</Typography>;
    }
    if (!customer.isApproved) {
      return <Typography color="green">Waitlisted</Typography>;
    }
    return <Typography color="purple">Onboarding</Typography>;
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    customer: Customer
  ) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    setSelectedCustomer(customer);
  };
  const handleClose = (option: string) => {
    switch (option) {
      case "approve": {
        // Approve account
        const modal = showModal(ApproveUserDialog, {
          closeHandler() {
            modal.destroy();
            load();
          },
          data: selectedCustomer,
        });
        break;
      }

      case "disable": {
        // Disable account
        const modal = showModal(DisableUserDialog, {
          closeHandler() {
            modal.destroy();
            load();
          },
          data: selectedCustomer,
        });
        break;
      }

      case "delete": {
        // Delete account
        const modal = showModal(DeleteUserDialog, {
          closeHandler() {
            modal.destroy();
            load();
          },
          data: selectedCustomer,
        });
        break;
      }
    }
    setSelectedCustomer(undefined);
  };

  useEffect(() => {
    setCurrentSection("Users & Customers");
    load();
  }, []);

  useEffect(() => {
    // Filter customers.
    load();
  }, [filter, search]);

  return (
    <Stack sx={{ overflow: "hidden" }}>
      <Stack
        flex={1}
        sx={{ p: "24px", pt: "48px", overflow: "hidden" }}
        spacing={"8px"}
      >
        <Stack direction={"row"} spacing="8px">
          <Button variant="contained" disabled={busy} onClick={load}>
            Refresh
          </Button>
          <OptionsGroup
            onChange={(o) => setFilter(o?.value as any)}
            title="Filter"
            defaultOption="all"
            options={[
              { label: "All", value: "all" },
              { label: "Waitlisted", value: "waitlisted" },
              { label: "Subscribed", value: "subscribed" },
              { label: "Disabled", value: "disabled" },
              { label: "Deleted", value: "deleted" },
              { label: "Search", value: "custom" },
            ]}
          />
          {filter === "custom" && (
            <TextInput
              value={search}
              onUpdate={(t) => setSearch(t)}
              label="Search"
              InputProps={{ endAdornment: <Search /> }}
              size="small"
            />
          )}
        </Stack>
        {/* The table for history */}
        <TableContainer sx={{ overflow: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>UID</TableCell>
                <TableCell align="center">Registered</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Subscribed</TableCell>
                <TableCell align="center">Last Sign In</TableCell>
                <TableCell align="center">Referrer</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers?.map((l) => (
                <>
                  <TableRow
                    sx={{
                      transition: "all .1s",
                    }}
                  >
                    <TableCell>{l.uid}</TableCell>
                    <TableCell align="center">
                      {moment(new Date(l.dateRegistered)).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </TableCell>
                    <TableCell align="center">{l.name}</TableCell>
                    <TableCell align="center">{l.email}</TableCell>
                    <TableCell align="center">
                      {l.billing?.subscriptions?.length > 0 ? "Yes" : "N/A"}
                    </TableCell>
                    <TableCell align="center">
                      {moment(new Date(l.lastSignIn)).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}{" "}
                      ({moment(new Date(l.lastSignIn)).fromNow()})
                    </TableCell>
                    <TableCell align="center">{l.referrer}</TableCell>
                    <TableCell align="center">{GetStatus(l)}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        disabled={busy}
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => handleClick(e, l)}
                      >
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              maxHeight: 30 * 4.5,
              width: "20ch",
            },
          }}
        >
          <MenuItem
            key={"approve"}
            disabled={selectedCustomer?.isApproved}
            onClick={() => {
              setAnchorEl(null);
              handleClose("approve");
            }}
          >
            <ListItemIcon>
              <CheckSharp color="success" />
            </ListItemIcon>
            Approve
          </MenuItem>
          <MenuItem
            disabled={selectedCustomer?.disabled}
            key={"disable"}
            onClick={() => {
              setAnchorEl(null);
              handleClose("disable");
            }}
          >
            <ListItemIcon>
              <StopCircle />
            </ListItemIcon>
            Disable
          </MenuItem>
          <MenuItem
            disabled={selectedCustomer?.deleted}
            key={"delete"}
            onClick={() => {
              setAnchorEl(null);
              handleClose("delete");
            }}
          >
            <ListItemIcon>
              <DeleteForever color="error" />
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>
      </Stack>
    </Stack>
  );
}
