import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { aCurrentSection } from "../states/ui";
import { enqueueSnackbar } from "notistack";
import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Settings, UpdateSharp } from "@mui/icons-material";
import { useModal } from "mui-modal-provider";
import ServiceSettingsDialog from "../dialogs/ServiceSettings";
import { GetConfig, GetServices, UpdateService } from "../core/rest";
import { Service } from "../core/service";
import moment from "moment";

export default function SectionBots() {
  const [busy, setBusy] = React.useState(false);
  const [bots, setBots] = React.useState<Service[]>([]);
  const [, setCurrentSection] = useRecoilState(aCurrentSection);
  const [config, setConfig] = React.useState<{
    image: string;
    version: string;
  }>();
  const { showModal } = useModal();

  async function load() {
    try {
      setBusy(true);
      const _bots = await GetServices();
      if (_bots) {
        setBots(_bots);
      }
      const _cfg = await GetConfig("default_image");
      if (_cfg) {
        setConfig(_cfg);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data, please try again.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function updateService(customerId: string) {
    try {
      setBusy(true);
      await UpdateService(customerId);
    } catch (err: any) {
      enqueueSnackbar("Error sending data, please try again.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  function showSettingsDialog() {
    const modal = showModal(ServiceSettingsDialog, {
      closeHandler() {
        modal.destroy();
      },
    });
  }

  useEffect(() => {
    setCurrentSection("Bot Services");
    load();
  }, []);
  return (
    <Stack sx={{ overflow: "hidden" }}>
      <Stack
        flex={1}
        sx={{ p: "24px", pt: "48px", overflow: "hidden" }}
        spacing={"8px"}
      >
        <Stack direction={"row"}>
          <Button variant="contained" disabled={busy} onClick={load}>
            Refresh
          </Button>
          <IconButton onClick={showSettingsDialog}>
            <Settings />
          </IconButton>
        </Stack>

        <TableContainer sx={{ overflow: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left">Deployment ID</TableCell>
                <TableCell align="center">Image</TableCell>
                <TableCell align="center">Created at</TableCell>
                <TableCell align="center">Replicas</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bots.map((bot, ind) => (
                <TableRow>
                  <TableCell align="left">{bot.metadata.name}</TableCell>
                  <TableCell align="center">
                    {bot?.spec?.template?.spec?.containers?.[0]?.image}
                  </TableCell>
                  <TableCell align="center">
                    {moment(new Date(bot.metadata.creationTimestamp)).fromNow()}
                  </TableCell>
                  <TableCell align="center">{bot.status.replicas}</TableCell>
                  <TableCell align="right">
                    {config &&
                      config.image &&
                      config.image !==
                        bot?.spec?.template?.spec?.containers?.[0]?.image && (
                        <IconButton
                          onClick={() =>
                            updateService(bot.spec.template.metadata.labels.UID)
                          }
                          title="Update to the latest version."
                        >
                          <UpdateSharp />
                        </IconButton>
                      )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
}
