import { useEffect, useState } from "react";
import { DialogProps } from "./DialogProps";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { ApproveWaitlistCustomer, DisableCustomer } from "../core/rest";
import { enqueueSnackbar } from "notistack";
import { Warning } from "@mui/icons-material";
import { Customer } from "@announcely/models";
import moment from "moment";
import TextInput from "../components/TextInput";

export default function DisableUserDialog(props: DialogProps<Customer>) {
  const [busy, setBusy] = useState(false);
  const [reasons, setReasons] = useState("");
  const [sendReasons, setSendReasons] = useState(false);

  async function disable() {
    try {
      setBusy(true);
      // Disable
      await DisableCustomer(props.data?.uid!, reasons, sendReasons);
      enqueueSnackbar("User has been approved.", { variant: "success" });
      props.closeHandler();
    } catch (err: any) {
      enqueueSnackbar("Error approving customer, please try again.", {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {}, []);

  return (
    <Dialog open fullWidth onClose={props.closeHandler}>
      <DialogTitle>
        <Typography>Disable User {props.data?.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing="18px">
          <Typography>
            Are you sure you want to disable the account for user{" "}
            {props.data?.name} with email <b>{props.data?.email}</b>?
          </Typography>
          <Alert color="warning" variant="outlined" icon={<Warning />}>
            The user last signed in on{" "}
            <b>
              {moment(props.data?.lastSignIn).format("MMM DD, YYYY HH:mm:ss")}
            </b>
          </Alert>
          <TextInput
            value={reasons}
            onUpdate={(t) => setReasons(t)}
            multiline
            rows={8}
            label="Reasons"
            placeholder="Please provide any reasons for disabling this user account."
          />
        </Stack>
        <Stack direction={"row"} sx={{ mt: "10px" }} alignItems={"center"}>
          <Checkbox onChange={(c) => setSendReasons(c.target.checked)} />
          <Typography>Send user an email with the reasons above.</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.closeHandler}
          disabled={busy}
          variant="text"
          sx={{ alignSelf: "end" }}
          size="large"
        >
          Nevermind
        </Button>
        <Button
          onClick={disable}
          disabled={busy}
          color="error"
          variant="contained"
          sx={{ alignSelf: "end" }}
          size="large"
        >
          Disable
        </Button>
      </DialogActions>
    </Dialog>
  );
}
