import { KeyboardArrowDown } from "@mui/icons-material";
import { FormControl, MenuItem, Select, useTheme } from "@mui/material";
import React from "react";

export type ComboBoxItem = {
	object: any;
	name: string;
	index: number;
};

export type ComboBoxProps = {
	label: string;
	required?: boolean;
	values?: ComboBoxItem[];
	default?: number;
	value?: number;
	placeholder?: string;
	fullWidth?: boolean;
	changed?: (item?: ComboBoxItem) => void;
	disabled?: boolean;
};

export default function ComboBox(props: ComboBoxProps) {
	const theme = useTheme();
	return (
		<FormControl
			disabled={props.disabled}
			variant="filled"
			required={props.required}
			fullWidth={props.fullWidth}
		>
			<Select
				size="small"
				variant="outlined"
				required={props.required}
				onChange={(c) =>
					props.changed?.(
						props.values?.find((f) => f.index === c.target.value)
					)
				}
				defaultValue={props.default}
				value={props.value}
				IconComponent={KeyboardArrowDown}
				sx={{
					borderRadius: "15px",
					border: props.value
						? `1px solid ${theme.palette.primary.main}`
						: "1px solid #414141",
				}}
			>
				<MenuItem key={-1} value={-1} disabled>
					{props.placeholder}
				</MenuItem>
				{props.values?.map((c) => (
					<MenuItem key={c.index} value={c.index}>
						{c.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
