import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { aCurrentSection } from "../states/ui";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import TextInput from "../components/TextInput";
import { useSnackbar } from "notistack";
import moment from "moment";
import { FbAuth } from "../core/firebase";
import {
	EmailAuthProvider,
	reauthenticateWithCredential,
	updatePassword,
	updateProfile,
} from "firebase/auth";
import { useModal } from "mui-modal-provider";

export default function SectionSettings() {
	const [, setCurrentSection] = useRecoilState(aCurrentSection);
	const [profile, setProfile] = useState<any>();
	const [oldPassword, setOldPassword] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const [fullName, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [busy, setBusy] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { showModal } = useModal();

	async function loadProfile() {
		try {
			// We show data on UI from firebase that is available.
			setFullName(FbAuth.currentUser?.displayName || "");
			setEmail(FbAuth.currentUser?.email || "");
		} catch (err: any) {
			// Error loading profile.
			enqueueSnackbar("Error loading data. " + err.message, {
				variant: "error",
			});
		}
	}

	async function changePassword() {
		if (!oldPassword) {
			enqueueSnackbar("Please enter your old password.", {
				variant: "error",
			});
			return;
		}
		if (password !== password2) {
			enqueueSnackbar("Passwords do not match.", { variant: "error" });
			return;
		}
		// Update
		try {
			setBusy(true);
			const creds = EmailAuthProvider.credential(
				profile!.email,
				oldPassword
			);
			const resp = await reauthenticateWithCredential(
				FbAuth.currentUser!,
				creds
			);
			// Proceed to update password.
			await updatePassword(resp.user, password);
			enqueueSnackbar("Your password has been updated.", {
				variant: "success",
			});
		} catch (err: any) {
			if (err.code && err.code === "auth/wrong-password") {
				enqueueSnackbar("Incorrect password. Please try again.", {
					variant: "error",
				});
			} else {
				enqueueSnackbar("Error updating your password.", {
					variant: "error",
				});
				console.log(err);
			}
		}
		setBusy(false);
	}

	async function saveProfile() {
		if (!profile?.name) {
			enqueueSnackbar("Please enter your full name.", {
				variant: "error",
			});
			return;
		}
		// Update
		try {
			setBusy(true);
			// Proceed to update password.
			await updateProfile(FbAuth.currentUser!, {
				displayName: fullName,
			});
			enqueueSnackbar("Your profile has been updated.", {
				variant: "success",
			});
		} catch (err: any) {
			enqueueSnackbar("Error updating your profile.", {
				variant: "error",
			});
			console.log(err);
		}
		setBusy(false);
	}

	useEffect(() => {
		setCurrentSection("Settings");
		loadProfile();
	}, []);
	return (
		<Stack sx={{ overflow: "hidden" }}>
			<Stack
				flex={1}
				sx={{ p: "24px", pt: "48px", overflow: "hidden" }}
				spacing={"8px"}
			>
				<Typography fontSize={"18px"} color={"#A5A5A5"}>
					Profile
				</Typography>
				<Stack direction={"row"} sx={{ width: "100%" }} spacing={"8px"}>
					<Stack spacing={"8px"} flex={1}>
						<Typography>Update Password</Typography>
						<TextInput
							type="password"
							label="Current Password"
							value={oldPassword}
							onUpdate={(c) => setOldPassword(c)}
						/>
						<TextInput
							type="password"
							label="New Password"
							value={password}
							onUpdate={(c) => setPassword(c)}
						/>
						<TextInput
							type="password"
							label="Confirm Password"
							value={password2}
							onUpdate={(c) => setPassword2(c)}
						/>
						<Button
							onClick={changePassword}
							disabled={busy}
							variant="contained"
							sx={{
								background: "white",
								color: "black",
								borderRadius: "25px",
								alignSelf: "start",
								":hover": {
									color: "#FFFA",
								},
							}}
						>
							Change Password
						</Button>
					</Stack>
					<Stack spacing={"8px"} flex={1}>
						<Typography>Personal Information</Typography>
						<TextInput
							key={profile}
							label="Name"
							value={fullName}
							onUpdate={(c) => setFullName(c)}
						/>
						<TextInput
							key={profile}
							label="Email"
							value={email}
							disabled
						/>
						{FbAuth.currentUser?.emailVerified === false && (
							<Typography sx={{ opacity: ".7" }} color={"error"}>
								Unverified. Please verify your email by clicking
								link sent to your email.
							</Typography>
						)}
						<Button
							disabled={busy}
							onClick={saveProfile}
							variant="contained"
							sx={{
								background: "white",
								color: "black",
								borderRadius: "25px",
								alignSelf: "start",
								":hover": {
									color: "#FFFA",
								},
							}}
						>
							Save
						</Button>
						{profile && (
							<>
								<Stack direction={"row"} spacing={"4px"}>
									<Typography sx={{ opacity: ".7" }}>
										Registered at{" "}
									</Typography>
									<Typography>
										{moment(
											new Date(profile?.dateRegistered)
										).format("DD/MM/YYYY HH:mm:ss")}
									</Typography>
								</Stack>
								<Stack direction={"row"} spacing={"4px"}>
									<Typography sx={{ opacity: ".7" }}>
										Last sign in{" "}
									</Typography>
									<Typography>
										{moment(
											new Date(profile?.lastSignIn)
										).format("DD/MM/YYYY HH:mm:ss")}
									</Typography>
								</Stack>
							</>
						)}
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
}
