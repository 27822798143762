import { Chip, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { LOGO_LIGHT_BLUE_HORIZONTAL } from "../assets";
import SidebarButton from "./SidebarButton";
import {
  DashboardRounded,
  PeopleRounded,
  SettingsRounded,
  Computer,
  Terminal,
  Money,
  PersonAddAlt,
  Discount,
  AdminPanelSettings,
  PunchClockSharp,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router";
import { AppConfig } from "../config";

export default function Sidebar() {
  const loc = useLocation();
  const nav = useNavigate();
  return (
    <Stack sx={{ m: "32px" }} spacing={"8px"} alignItems={"center"}>
      {/* The logo  */}
      <img
        src={LOGO_LIGHT_BLUE_HORIZONTAL}
        width={"256px"}
        alt="Announcely Logo"
      />
      <Typography sx={{ maxWidth: "80%", textAlign: "center" }}>
        Management Console
      </Typography>
      <Stack sx={{ pt: "80px", width: "80%" }} flex={1}>
        <SidebarButton
          active={loc.pathname.toLowerCase() === "/portal/dashboard"}
          onClick={() => nav("dashboard")}
          startIcon={<DashboardRounded fontSize="large" />}
        >
          Dashboard
        </SidebarButton>
        <SidebarButton
          active={loc.pathname.toLowerCase() === "/portal/customers"}
          onClick={() => nav("customers")}
          startIcon={<PeopleRounded fontSize="large" />}
        >
          Users & Customers
        </SidebarButton>
        <SidebarButton
          active={loc.pathname.toLowerCase() === "/portal/bots"}
          onClick={() => nav("bots")}
          startIcon={<Computer fontSize="large" />}
        >
          Bots
        </SidebarButton>
        <SidebarButton
          active={loc.pathname.toLowerCase() === "/portal/revenues"}
          onClick={() => nav("revenues")}
          startIcon={<Money fontSize="large" />}
        >
          Revenues
        </SidebarButton>
        <SidebarButton
          active={loc.pathname.toLowerCase() === "/portal/sponsors"}
          onClick={() => nav("sponsors")}
          startIcon={<PersonAddAlt fontSize="large" />}
        >
          Sponsors
        </SidebarButton>
        <SidebarButton
          active={loc.pathname.toLowerCase() === "/portal/coupons"}
          onClick={() => nav("coupons")}
          startIcon={<Discount fontSize="large" />}
        >
          Coupons
        </SidebarButton>
        <SidebarButton
          active={loc.pathname.toLowerCase() === "/portal/admins"}
          onClick={() => nav("admins")}
          startIcon={<AdminPanelSettings fontSize="large" />}
        >
          Admins
        </SidebarButton>

        <SidebarButton
          active={loc.pathname.toLowerCase() === "/portal/configs"}
          onClick={() => nav("configs")}
          startIcon={<Terminal fontSize="large" />}
        >
          Configuration
        </SidebarButton>
        <SidebarButton
          active={loc.pathname.toLowerCase() === "/portal/settings"}
          onClick={() => nav("settings")}
          startIcon={<SettingsRounded fontSize="large" />}
        >
          Settings
        </SidebarButton>

        <Stack
          flex={1}
          spacing={"8px"}
          sx={{ mx: "8px" }}
          direction={"row"}
          alignItems={"end"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing="8px">
            <Typography fontSize={12}>Version: </Typography>
            <Tooltip title={`Revision: ${AppConfig.revision}`}>
              <Chip label={AppConfig.version} size="small" />
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
