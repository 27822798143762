import React, { useEffect, useState } from "react";
import { DialogProps } from "./DialogProps";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import TextInput from "../components/TextInput";
import { AddAdmin, GetConfig, SetConfig } from "../core/rest";
import { enqueueSnackbar } from "notistack";
import { Warning } from "@mui/icons-material";
import ComboBox, { ComboBoxItem } from "../components/ComboBox";

export default function AddAdminDialog(props: DialogProps<any>) {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<ComboBoxItem>();
  const [roles, setRoles] = useState<ComboBoxItem[]>([]);

  const [busy, setBusy] = useState(false);

  async function saveConfig() {
    setBusy(true);
    try {
      // Make sure email and role are set
      if (email && role?.object) {
        // Save
        await AddAdmin(email, role.object);
        enqueueSnackbar("Admin added.", { variant: "success" });
        props.closeHandler();
      } else {
        enqueueSnackbar("Please enter the email address and specify a role.", {
          variant: "error",
        });
      }
    } catch (err: any) {
      enqueueSnackbar("Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    const _newRoles: ComboBoxItem[] = [];
    _newRoles.push({
      index: _newRoles.length,
      name: "Viewer",
      object: "viewer",
    });
    _newRoles.push({
      index: _newRoles.length,
      name: "Administrator",
      object: "admin",
    });
    setRoles(_newRoles);
  }, []);

  return (
    <Dialog open fullWidth onClose={props.closeHandler}>
      <DialogTitle>
        <Typography>Add an Admin</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing="18px">
          <Alert color="warning" variant="outlined" icon={<Warning />}>
            Be careful with the `admin` role as it will give the user complete
            access to the platform configurations.
          </Alert>
          <TextInput
            disabled={busy}
            value={email}
            onUpdate={(t) => setEmail(t)}
            label="Email"
            helperText="The email address of the new admin. Must be a google email."
          />

          <Stack>
            <Typography>Role</Typography>
            <ComboBox
              value={role?.index}
              values={roles}
              label="Role"
              default={-1}
              placeholder="Select a role..."
              required
              changed={(r) => setRole(r)}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.closeHandler}
          disabled={busy}
          variant="text"
          sx={{ alignSelf: "end" }}
          size="large"
        >
          Cancel
        </Button>
        <Button
          onClick={saveConfig}
          disabled={busy}
          variant="contained"
          sx={{ alignSelf: "end" }}
          size="large"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
